



























import type { PropType } from 'vue';
import { Component } from 'vue';
import { mapGetters, mapState } from 'vuex';
import CopyStakeDashboardStatisticTile from '@/views/CopyStake/CopyStakeDashboard/CopyStakeDashboardStatisticTile.vue';
import { toPercents } from '@/helpers/decimalsHelpers';
import { camelCase, startCase } from 'lodash';
import {
  DateRangeParams,
  ICopyStakeDashboardStatsDataResponse,
  ICopyStakeDashboardViewersDataResponse
} from '@/api/schema';
import {
  getCopyStakeDashboardStats,
  getCopyStakeDashboardLoyaltyStats,
  getCopyStakeDashboardViewers
} from '@/api/CopyStake';
import type { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const enum EStatsKey {
  USER_STATS = 'USER_STATS',
  BETTING_ACTIVITY = 'BETTING_ACTIVITY',
  AUDIENCE_INSIGHTS = 'AUDIENCE_INSIGHTS'
}

interface ITileConfig {
  icon: Component;
  title: string;
  titleTooltip?: string;
  value: number | string;
}

type TStatsTilesConfig = {
  [key in EStatsKey]: ITileConfig[];
};

const UsersIcon = (): any =>
  import('@/assets/images/icons/copyStakeDashboard/users.svg?inline');
const ActiveUsers = (): any =>
  import('@/assets/images/icons/copyStakeDashboard/active-users.svg?inline');
const ActivePlayers = (): any =>
  import(
    '@/assets/images/icons/copyStakeDashboard/active-players.svg?inline'
  );
const BetVolume = (): any =>
  import('@/assets/images/icons/copyStakeDashboard/bet-volume.svg?inline');
const CopyStakeBetVolume = (): any =>
  import(
    '@/assets/images/icons/copyStakeDashboard/copy-stake-bet-volume.svg?inline'
  );
const BetVolumeShare = (): any =>
  import(
    '@/assets/images/icons/copyStakeDashboard/bet-volume-share.svg?inline'
  );
const Viewers = (): any =>
  import('@/assets/images/icons/copyStakeDashboard/viewers.svg?inline');
const UniqueViewers = (): any =>
  import(
    '@/assets/images/icons/copyStakeDashboard/unique-viewers.svg?inline'
  );
const ViewerWithBet = (): any =>
  import('@/assets/images/icons/copyStakeDashboard/viewer-bet.svg?inline');

export default {
  name: 'CopyStakeDashboardStats',
  props: {
    dates: {
      type: Object as PropType<DateRangeParams>,
      required: true,
      default: (): DateRangeParams => {
        return {} as DateRangeParams;
      }
    }
  },
  components: {
    CopyStakeDashboardStatisticTile
  },
  data: (): any => ({
    loading: false,
    statsData: {
      [EStatsKey.USER_STATS]: {
        users: undefined,
        activeUsers: undefined
      },
      [EStatsKey.BETTING_ACTIVITY]: {
        betVolume: undefined,
        copyStakeBetVolume: undefined
      },
      [EStatsKey.AUDIENCE_INSIGHTS]: {
        viewers: undefined,
        uniqueViewers: undefined,
        viewersWithBet: undefined
      }
    }
  }),
  computed: {
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('SuperAdmin', ['currentAccount']),
    ...mapState('app', ['isSuperAdmin']),
    isCopyStakeAccount(): boolean {
      return (
        this.isCopyStakeRole ||
        (this.isSuperAdmin && this.currentAccount.copyStakeAccount)
      );
    },
    mapTilesConfigs(): { [key: string]: ITileConfig } {
      return {
        users: {
          icon: UsersIcon as Component,
          title: 'Users',
          titleTooltip: 'Total count of unique users with BET or BET Copied',
          value: !isNaN(this.statsData[EStatsKey.USER_STATS].users)
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.USER_STATS].users,
                `0,0`
              )
            : '-'
        },
        activeUsers: {
          icon: ActiveUsers as Component,
          title: 'Active Users',
          titleTooltip: 'Count of users with at least one BET Copied',
          value: !isNaN(this.statsData[EStatsKey.USER_STATS].activeUsers)
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.USER_STATS].activeUsers,
                `0,0`
              )
            : '-'
        },
        activePlayers: {
          icon: ActivePlayers as Component,
          title: 'CopyStake Players Share',
          titleTooltip: 'Percentage of active players',
          value:
            !isNaN(this.statsData[EStatsKey.USER_STATS].users) &&
            !isNaN(this.statsData[EStatsKey.USER_STATS].activeUsers)
              ? toPercents(
                  this.statsData[EStatsKey.USER_STATS].activeUsers /
                    this.statsData[EStatsKey.USER_STATS].users,
                  2
                ) + '%'
              : '-'
        },
        betVolume: {
          icon: BetVolume as Component,
          title: 'Bet Volume',
          titleTooltip: 'Total sum of BETs and BET Copied over a period',
          value: !isNaN(this.statsData[EStatsKey.BETTING_ACTIVITY].betVolume)
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.BETTING_ACTIVITY].betVolume,
                `0,0.[00]`
              ) + ' USD'
            : '-'
        },
        copyStakeBetVolume: {
          icon: CopyStakeBetVolume as Component,
          title: 'CopyStake Bet Volume',
          titleTooltip:
            'The volume of bets placed on the CopyStake over a period',
          value: !isNaN(
            this.statsData[EStatsKey.BETTING_ACTIVITY].copyStakeBetVolume
          )
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.BETTING_ACTIVITY].copyStakeBetVolume,
                `0,0.[00]`
              ) + ' USD'
            : '-'
        },
        copyStakeBetVolumeShare: {
          icon: BetVolumeShare as Component,
          title: 'CopyStake Bet Volume Share',
          titleTooltip: 'Percentage of CopyStake Bet Volume',
          value:
            !isNaN(
              this.statsData[EStatsKey.BETTING_ACTIVITY].copyStakeBetVolume
            ) && !isNaN(this.statsData[EStatsKey.BETTING_ACTIVITY].betVolume)
              ? toPercents(
                  this.statsData[EStatsKey.BETTING_ACTIVITY]
                    .copyStakeBetVolume /
                    this.statsData[EStatsKey.BETTING_ACTIVITY].betVolume,
                  2
                ) + '%'
              : '-'
        },
        viewers: {
          icon: Viewers as Component,
          title: 'Viewers',
          titleTooltip: 'Total count of all viewers across streams',
          value: !isNaN(this.statsData[EStatsKey.AUDIENCE_INSIGHTS].viewers)
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.AUDIENCE_INSIGHTS].viewers,
                `0,0`
              )
            : '-'
        },
        uniqueViewers: {
          icon: UniqueViewers as Component,
          title: 'Unique Viewers',
          titleTooltip: 'Number of distinct viewers (counted once)',
          value: !isNaN(
            this.statsData[EStatsKey.AUDIENCE_INSIGHTS].uniqueViewers
          )
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.AUDIENCE_INSIGHTS].uniqueViewers,
                `0,0`
              )
            : '-'
        },
        viewerWithBet: {
          icon: ViewerWithBet as Component,
          title: 'Viewer with Bet',
          titleTooltip: 'Viewers who placed at least one BET Copied',
          value: !isNaN(
            this.statsData[EStatsKey.AUDIENCE_INSIGHTS].viewersWithBet
          )
            ? this.$options.filters.numeralSpaces(
                this.statsData[EStatsKey.AUDIENCE_INSIGHTS].viewersWithBet,
                `0,0`
              )
            : '-'
        }
      };
    },
    statsTilesConfig(): TStatsTilesConfig {
      const commonTiles = {
        [EStatsKey.AUDIENCE_INSIGHTS]: [
          this.mapTilesConfigs.viewers,
          this.mapTilesConfigs.uniqueViewers,
          this.mapTilesConfigs.viewerWithBet
        ]
      };

      return this.isCopyStakeAccount
        ? ({
            [EStatsKey.USER_STATS]: [
              this.mapTilesConfigs.activeUsers,
              this.mapTilesConfigs.copyStakeBetVolume
            ],
            ...commonTiles
          } as TStatsTilesConfig)
        : {
            [EStatsKey.USER_STATS]: [
              this.mapTilesConfigs.users,
              this.mapTilesConfigs.activeUsers,
              this.mapTilesConfigs.activePlayers
            ],
            [EStatsKey.BETTING_ACTIVITY]: [
              this.mapTilesConfigs.betVolume,
              this.mapTilesConfigs.copyStakeBetVolume,
              this.mapTilesConfigs.copyStakeBetVolumeShare
            ],
            ...commonTiles
          };
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getData();
        }
      },
      immediate: true
    },
    dates: 'getData'
  },
  methods: {
    transformTitle(key: EStatsKey): string {
      switch (key) {
        case EStatsKey.USER_STATS:
          return 'User Statistics';
        default:
          return startCase(camelCase(key));
      }
    },
    async getData(): Promise<void> {
      this.loading = true;

      const promises: [
        Promise<ICopyStakeDashboardStatsDataResponse>,
        Promise<ICopyStakeDashboardViewersDataResponse>,
        Promise<ICopyStakeDashboardStatsDataResponse | undefined>
      ] = [
        getCopyStakeDashboardStats(this.dates),
        getCopyStakeDashboardViewers(this.dates),
        !this.isCopyStakeAccount
          ? getCopyStakeDashboardLoyaltyStats(this.dates)
          : Promise.resolve(undefined)
      ];

      await Promise.allSettled(promises)
        .then(([copystakeResult, viewersResult, loyaltyResult]) => {
          if (copystakeResult.status === 'fulfilled') {
            const copystakeStats = copystakeResult.value;

            this.statsData[EStatsKey.USER_STATS].activeUsers =
              copystakeStats.activeUsers;
            this.statsData[EStatsKey.BETTING_ACTIVITY].copyStakeBetVolume =
              copystakeStats.betVolume;
          } else {
            errorToastMessage(copystakeResult.reason as AxiosError);
          }

          if (viewersResult.status === 'fulfilled') {
            const viewersStats = viewersResult.value;
            this.statsData[EStatsKey.AUDIENCE_INSIGHTS].viewers =
              viewersStats.viewers;
            this.statsData[EStatsKey.AUDIENCE_INSIGHTS].uniqueViewers =
              viewersStats.uniqueViewers;
            this.statsData[EStatsKey.AUDIENCE_INSIGHTS].viewersWithBet =
              viewersStats.viewersWithBet;
          } else {
            errorToastMessage(viewersResult.reason as AxiosError);
          }

          if (loyaltyResult?.status === 'fulfilled') {
            const loyaltyStats = loyaltyResult.value;
            if (loyaltyStats) {
              this.statsData[EStatsKey.USER_STATS].users =
                loyaltyStats.activeUsers;
              this.statsData[EStatsKey.BETTING_ACTIVITY].betVolume =
                loyaltyStats.betVolume;
            }
          } else {
            errorToastMessage(loyaltyResult.reason as AxiosError);
          }
        })
        .catch((err: AxiosError): void => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.loading = false;
        });
    }
  }
};
