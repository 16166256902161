





















import Tooltip from '@/components/tooltip/Tooltip.vue';

export default {
  name: 'CopyStakeDashboardStatisticTile',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    iconComponent: {
      default: (): string => ''
    },
    title: {
      type: String,
      default: (): string => ''
    },
    titleTooltip: {
      type: String,
      default: (): string => ''
    },
    value: {
      type: String || Number,
      default: (): string => ''
    }
  },
  components: {
    Tooltip
  }
};
